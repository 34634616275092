import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData
} from '../../../domain/utils/contentUtils';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { ContainerFluid } from '../../responsive/atoms/ContainerFluid';
import { theme } from '../../../domain/theme';
import { ContentPackageHeader } from '../../responsive/organisms/ContentPackageHeader';

export const RightContentPackage = ({ data, isAboveTheFold, content }) => {
  const style = {
    header: css`
      position: relative;
      width: 100%;
      ${theme.font.family('boldWeb')};
      letter-spacing: 4px;
      margin: 0;
      margin-bottom: 0;
      text-align: unset;
      overflow: unset;
      padding: 5px 0 30px 60px;
      ${theme.mq.tablet} {
        font-size: 1.875rem;
        line-height: 2rem;
        padding: 10px 0 40px 40px;
      }
      ${theme.mq.small_desktop} {
        font-size: 2.25rem;
        line-height: 2.375rem;
        padding-bottom: 34px;
      }
      ${theme.mq.large_desktop} {
        font-size: 3rem;
        line-height: 3.125rem;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: #fc0;
        width: 1px;
        left: 30px;
        display: block;
        height: 98px;
        top: 4px;
        z-index: ${theme.zIndex('above')};
        ${theme.mq.tablet} {
          left: 1px;
        }
        ${theme.mq.small_desktop} {
          height: 106px;
        }
        ${theme.mq.large_desktop} {
          height: 132px;
          top: 6px;
        }
      }
    `
  };
  const contentBackgroundColor = content.backgroundColor
    ? content.backgroundColor
    : 'black';
  return (
    <GridColumn width={[12, 7, 7, 7]} childrenMarginBottom="l">
      {data.contentPackageHeader && (
        <ContentPackageHeader
          header={data.contentPackageHeader}
          styles={style.header}
        />
      )}
      {data.articleCardGlobalMedium &&
        data.articleCardGlobalMedium.map((card, index) => {
          const key = `articleCardGlobalMedium_${index}`;
          if (card.props) {
            return (
              <CardItemVerticalLegacy
                {...card.props}
                isAboveTheFold={isAboveTheFold}
                key={key}
                backgroundColor={contentBackgroundColor}
                imageMaxWidths={{
                  mobile: 703,
                  tablet: 537,
                  small_desktop: 658,
                  large_desktop: 839
                }}
              />
            );
          }
          if (index === 0) {
            return card.entity ? (
              <CardItemVerticalLegacy
                data={removeAuthorsAndDateFromCardData(
                  mapContentToCardData(card.entity)
                )}
                size="medium"
                aspect="4x3"
                isAboveTheFold={isAboveTheFold}
                key={key}
                backgroundColor={contentBackgroundColor}
                imageMaxWidths={{
                  mobile: 703,
                  tablet: 537,
                  small_desktop: 658,
                  large_desktop: 839
                }}
              />
            ) : null;
          }
          return card.entity ? (
            <CardItemVerticalLegacy
              data={removeAuthorsAndDateFromCardData(
                mapContentToCardData(card.entity)
              )}
              size="medium"
              aspect="16x9"
              key={key}
              backgroundColor={contentBackgroundColor}
              imageMaxWidths={{
                mobile: 703,
                tablet: 537,
                small_desktop: 658,
                large_desktop: 839
              }}
            />
          ) : null;
        })}
      <ContainerFluid>
        <GridRow gutter="none">
          {data.articleCardRightXs &&
            data.articleCardRightXs.map((card, index) => {
              const key = `articleCardRightXs_${index}`;
              if (card.props) {
                return (
                  <GridColumn
                    width={[12, 6, 6, 6]}
                    childrenMarginBottom="l"
                    key={key}
                  >
                    <CardItemVerticalLegacy
                      {...card.props}
                      backgroundColor={contentBackgroundColor}
                      imageMaxWidths={{
                        mobile: 767,
                        tablet: 268,
                        small_desktop: 329,
                        large_desktop: 419
                      }}
                    />
                  </GridColumn>
                );
              }
              return card.entity ? (
                <GridColumn
                  width={[12, 6, 6, 6]}
                  childrenMarginBottom="l"
                  key={key}
                >
                  <CardItemVerticalLegacy
                    data={removeAuthorsAndDateFromCardData(
                      mapContentToCardData(card.entity)
                    )}
                    size="small"
                    aspect="16x9"
                    backgroundColor={contentBackgroundColor}
                    imageMaxWidths={{
                      mobile: 767,
                      tablet: 268,
                      small_desktop: 329,
                      large_desktop: 419
                    }}
                  />
                </GridColumn>
              ) : null;
            })}
        </GridRow>
      </ContainerFluid>
    </GridColumn>
  );
};

RightContentPackage.defaultProps = {
  isAboveTheFold: false,
  content: undefined
};

RightContentPackage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool,
  content: PropTypes.objectOf(PropTypes.any)
};
