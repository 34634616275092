import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Ad } from '../../responsive/atoms/Ad';
import { LeftContentPackage } from './LeftContentPackage';
import { RightContentPackage } from './RightContentPackage';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { GridRow } from '../../responsive/atoms/Grid';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import { theme } from '../../../domain/theme';
import { Container } from '../../responsive/atoms/Container';
import { ParagraphWrapper } from '../ParagraphWrapper';

const LeftAndRightContentPackageInner = props => {
  const { data, content, isAboveTheFold } = props;
  const style = {
    tabletAd: css`
      display: none;
      ${theme.mq.tablet} {
        display: inline-block;
        text-align: center;
        width: 100%;
      }
      ${theme.mq.small_desktop} {
        display: none;
      }
    `
  };
  const container = theme.grid.containers('main');
  const contentBackgroundColor = content.backgroundColor
    ? content.backgroundColor
    : 'black';
  return (
    <ContentPackageWrapper
      backgroundColor={
        content.backgroundColor === 'black'
          ? 'true_black'
          : contentBackgroundColor
      }
      extraStyles={css`
        padding: 37px 0 60px;
        ${facePaint({
          'contain-intrinsic-size': [
            '0 3537px',
            '0 1730px',
            '0 1885px',
            '0 2168px'
          ]
        })}
      `}
    >
      <Container
        maxWidth={container.maxWidth}
        padding={['0 0', '0 $l', '0 $xl2']}
        childrenMarginBottom={container.childrenMarginBottom}
      >
        <GridRow gutter={[null, null, null, 'l']}>
          {data.leftContentPackage && (
            <LeftContentPackage
              content={content}
              data={data.leftContentPackage.entity}
              isAboveTheFold={isAboveTheFold}
            />
          )}
          {data.rightContentPackage && (
            <RightContentPackage
              content={content}
              data={data.rightContentPackage.entity}
              isAboveTheFold={isAboveTheFold}
            />
          )}
        </GridRow>
        <div css={style.tabletAd}>
          <Ad
            key={`left_right_package__${data.id}`}
            id={`left_right_package__${data.id}`}
            content={content}
          />
        </div>
      </Container>
    </ContentPackageWrapper>
  );
};

LeftAndRightContentPackageInner.defaultProps = {
  isAboveTheFold: false
};

LeftAndRightContentPackageInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const LeftAndRightContentPackage = ParagraphWrapper(
  LeftAndRightContentPackageInner
);
