import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Ad } from '../../responsive/atoms/Ad';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import {
  mapContentToCardData,
  removeAttributesFromCardData,
  removeAuthorsAndDateFromCardData,
  removeTaxonomyElementsFromCardData
} from '../../../domain/utils/contentUtils';
import { GridColumn } from '../../responsive/atoms/Grid';
import { theme } from '../../../domain/theme';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { ContentPackageHeader } from '../../responsive/organisms/ContentPackageHeader';

const style = {
  wrapper: css`
    background-color: #000;
    color: #fff;
    overflow: hidden;
    /*height: 100%;*/
    padding: 30px;
    ${theme.mq.small_desktop} {
      padding: 40px;
    }

    > div {
      margin-bottom: ${theme.spacing('base')};
      padding-bottom: ${theme.spacing('base')};
      border-bottom: 1px solid #555;
      position: relative;

      &:after {
        ${theme.font.family('regularWeb')};
        font-weight: 400;
        letter-spacing: 7px;
        line-height: 1.5;
        color: #fff;
        position: absolute;
        font-weight: 100;
        opacity: 0.15;
        bottom: 0;
        font-size: 6.25rem;
        height: 102px;
        right: 25px;

        position: absolute;
        z-index: 150;
        ${theme.mq.tablet} {
          font-size: 3.75rem;
          height: 61px;
          right: 20px;
        }
        ${theme.mq.small_desktop} {
          font-size: 5rem;
          height: 81px;
          right: 30px;
        }
        ${theme.mq.large_desktop} {
          font-size: 6.25rem;
          height: 102px;
          right: 26px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        height: 4px;
        right: 0;
        background-color: black;
        bottom: -2px;
        ${facePaint({ width: ['21%', '29%', '26%', '26%'] })}
      }

      &:hover:after {
        opacity: 1;
      }
    }
    > div:nth-of-type(1):after {
      content: '1';
      color: #fc0;
      opacity: 1;
    }
    > div:nth-of-type(2):after {
      content: '2';
    }
    > div:nth-of-type(3):after {
      content: '3';
    }
    > div:nth-of-type(4):after {
      content: '4';
    }
    > div:nth-of-type(5):after {
      content: '5';
    }
  `,
  ad: css`
    display: block;
    width: 374px;
    min-height: 357px;
    margin: 50px auto;
    padding: 50px 0;
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.05);
    ${theme.mq.tablet} {
      display: none;
    }
    ${theme.mq.small_desktop} {
      display: block;
      width: 358px;
    }
    ${theme.mq.large_desktop} {
      width: 100%;
    }
  `,
  header: css`
    position: relative;
    width: 100%;
    ${theme.font.family('boldWeb')};
    letter-spacing: 4px;
    margin: 0;
    text-align: unset;
    overflow: unset;
    padding: 5px 0 30px 60px;
    ${theme.mq.tablet} {
      font-size: 1.875rem;
      line-height: 2rem;
      padding: 10px 0 40px 40px;
    }
    ${theme.mq.small_desktop} {
      font-size: 2.25rem;
      line-height: 2.375rem;
      padding-bottom: 34px;
    }
    ${theme.mq.large_desktop} {
      font-size: 3rem;
      line-height: 3.125rem;
    }

    &:before {
      content: '';
      position: absolute;
      background-color: #fc0;
      width: 1px;
      left: 30px;
      display: block;
      height: 98px;
      top: 4px;
      z-index: ${theme.zIndex('above')};
      ${theme.mq.tablet} {
        left: 1px;
      }
      ${theme.mq.small_desktop} {
        height: 106px;
      }
      ${theme.mq.large_desktop} {
        height: 132px;
        top: 6px;
      }
    }
  `
};

export const LeftContentPackage = ({ data, content, isAboveTheFold }) => {
  const adId =
    data.articleCardNoImage?.[0]?.entity?.id ||
    data.articleCardNoImage?.[0]?.props?.data?.id ||
    data.articleCardGlobalSmall?.[0]?.entity?.id ||
    data.articleCardGlobalSmall?.[0]?.props?.data?.id;

  return (
    <GridColumn width={[12, 5, 5, 5]} childrenMarginBottom="xs">
      {data.contentPackageHeader && (
        <ContentPackageHeader
          header={data.contentPackageHeader}
          styles={style.header}
        />
      )}

      <div css={style.wrapper}>
        {data.articleCardGlobalSmall &&
          data.articleCardGlobalSmall.map((card, index) => {
            const key = `articleCardGlobalSmall_${index}`;
            if (index === 0) {
              if (card.props) {
                return (
                  <CardItemVerticalLegacy
                    isAboveTheFold={isAboveTheFold}
                    key={key}
                    {...card.props}
                    size="leftMedium"
                    backgroundColor="black"
                    canBeAnimated
                    imageMaxWidths={{
                      mobile: 703,
                      tablet: 374,
                      small_desktop: 461,
                      large_desktop: 585
                    }}
                  />
                );
              }
              return card.entity ? (
                <CardItemVerticalLegacy
                  data={removeTaxonomyElementsFromCardData(
                    removeAuthorsAndDateFromCardData(
                      mapContentToCardData(card.entity)
                    )
                  )}
                  size="leftMedium"
                  aspect="4x3"
                  isAboveTheFold={isAboveTheFold}
                  key={key}
                  backgroundColor="black"
                  canBeAnimated
                  imageMaxWidths={{
                    mobile: 703,
                    tablet: 374,
                    small_desktop: 461,
                    large_desktop: 585
                  }}
                />
              ) : null;
            }
            return null;
          })}
        {data.articleCardNoImage &&
          data.articleCardNoImage.map((card, index) => {
            const key = `articleCardNoImage_${index}`;
            if (card.props) {
              const { props } = card;
              props.data = removeTaxonomyElementsFromCardData(
                removeAttributesFromCardData(card.props.data, ['imageEntity'])
              );
              return (
                <CardItemVerticalLegacy
                  {...props}
                  excludeImage
                  key={key}
                  backgroundColor="black"
                  size="leftSmall"
                />
              );
            }
            return card.entity ? (
              <CardItemVerticalLegacy
                data={removeAttributesFromCardData(
                  removeTaxonomyElementsFromCardData(
                    removeAuthorsAndDateFromCardData(
                      mapContentToCardData(card.entity)
                    )
                  ),
                  ['imageEntity']
                )}
                size="leftSmall"
                aspect="4x3"
                key={key}
                backgroundColor="black"
                excludeImage
              />
            ) : null;
          })}
      </div>
      <div css={style.ad}>
        <Ad id={`left_package__${adId}`} content={content} />
      </div>
    </GridColumn>
  );
};

LeftContentPackage.defaultProps = {
  isAboveTheFold: false
};

LeftContentPackage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};
